import React from 'react';
import VideoGenre from '../domain/Genre/VideoGenre';

class IndexPage extends React.Component {
  public render(): JSX.Element {
    return <VideoGenre />;
  }
}

export default IndexPage;
